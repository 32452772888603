<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
                class="nav-link"
                @click="toggleVerticalMenuActive"
        >
          <feather-icon
                  icon="MenuIcon"
                  size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-button

              variant="outline-primary"
              @click="goOldOdin"
      >
        Volver a Odin 1.0
      </b-button>
    </div>
    -->
    <b-navbar-nav
            id="right-nav-menu-1"
            class="nav align-items-center ml-auto"
    >
      <dark-Toggler class="d-none d-lg-block" />
      <NotificationDropdown />

      <b-nav-item-dropdown
              :ref="`right-nav-menu-1-${componentId}`"
              right
              toggle-class="d-flex align-items-center dropdown-user-link"
              class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userLoggedIn.name ? userLoggedIn.name : "" }}
            </p>
            <span class="user-status" />
          </div>
          <b-avatar
                  size="40"
                  class="badge-minimal"
                  badge
                  badge-variant="success"
                  :text="avatarText(userLoggedIn.nombre_usuario+' '+userLoggedIn.apellidos_usuario)"
                  variant="light-primary"
          />
        </template>

        <b-dropdown-item-button
                disabled
                link-class="d-flex align-items-center p-1"
        >
          <b-avatar
                  size="40"
                  class="badge-minimal"
                  badge
                  badge-variant="success"
                  :text="avatarText(userLoggedIn.nombre_usuario+' '+userLoggedIn.apellidos_usuario)"
                  variant="light-primary"
          />
          <span class="ml-1">{{ userLoggedIn.nombre_usuario+" "+userLoggedIn.apellidos_usuario }}</span>
        </b-dropdown-item-button>
        <b-dropdown-divider />

        <template v-if="optionSection == 'settings'">
          <b-dropdown-item
                  link-class="d-flex align-items-center"
                  :to="{ name: 'profile' }"
          >
            <span> <feather-icon
                    size="16"
                    icon="UserIcon"
                    class="mr-50"
            /> Edit Account</span>
          </b-dropdown-item>

          <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click="actionMenu('settings')"
          >
            <span>
              <feather-icon
                      size="16"
                      icon="LockIcon"
                      class="mr-50"
              />
              Security
            </span>
            <feather-icon
                    size="16"
                    icon="ArrowRightIcon"
                    class="ml-50"
            />
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item link-class="d-flex align-items-center">
            <span><feather-icon
                    size="16"
                    icon="BellIcon"
                    class="mr-50"
            /> Notifications</span>
          </b-dropdown-item>
        </template>

        <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="logout()"
        >
          <span><feather-icon
                  size="16"
                  icon="LogOutIcon"
                  class="mr-50"
          /> Salir</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
  import { avatarText } from '@core/utils/filter'
  import { mapGetters } from 'vuex'
  import {
    BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownItemButton, BDropdownDivider, BAvatar,
  } from 'bootstrap-vue'
  import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
  import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
  import store from '@/store'

  export default {
    components: {
      BLink,
      BNavbarNav,
      BNavItemDropdown,
      BDropdownItem,
      BDropdownItemButton,
      BDropdownDivider,
      BAvatar,

      // Navbar Components
      DarkToggler,
      NotificationDropdown,
    },
    props: {
      toggleVerticalMenuActive: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        componentId: this.$getId('navbar'),
        avatarText,
        optionSection: 'base',
      }
    },
    computed: {
      ...mapGetters({
        userLoggedIn: 'auth/getUserLogged',
       }),

    },
    methods: {
      goOldOdin() {
        const url = window.atob(store.state.auth.backURL)
        window.location.href = url
      },
      actionMenu(playload) {
        this.optionSection = playload
        setTimeout(() => {
          this.$refs[`right-nav-menu-1-${this.componentId}`].visible = true
        }, 350)
      },
      logout() {
        this.$store
                .dispatch('auth/logout')
                .then(response => {
                  this.$handleSuccessResponse(response)
                })
                .catch(error => {
                  this.$handleErrorResponse(error)
                })
      },
    },
  }
</script>

<style lang="scss">
  #right-nav-menu-1 {
    .dropdown-menu {
      width: auto;
      .dropdown-item {
        justify-content: space-between;
      }
    }
  }
</style>
